.theme-selector {
  position: fixed;
  bottom: 10px;
  right: 10px;
  display: flex;
  flex-direction: column;
  z-index: 999;
  align-items: center;
  justify-content: center;

  .theme-selector-button {
    width: 2.5rem;
    height: 2.5rem;
    border: none;
    border-radius: 50%;
    padding: 0.4rem;
    background: var(--primary-color);
    box-shadow: var(--shadow-2);
    cursor: pointer;
    position: relative;
    transition: background 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--smoky-black) !important;
    &:hover {
      background: var(--primary-color);
    }
  }

  .theme-options {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 0.5rem;
    transform-origin: bottom;

    &.expanded {
      animation: grow 0.3s ease forwards;
    }

    &.collapsed {
      animation: shrink 0.3s ease forwards;
    }

    .theme-option {
      width: 2rem;
      height: 2rem;
      margin-top: 0.5rem;
      border: none;
      border-radius: 50%;
      margin-bottom: 5px;
      cursor: pointer;
      transition: transform 0.3s ease, background 0.3s ease;
      box-shadow: var(--shadow-2);
      display: flex;
      align-items: center;
      justify-content: center;

      &.active {
        transform: scale(1.1);
      }

      &:hover {
        transform: scale(1.2);
      }
    }
  }

  @keyframes grow {
    0% {
      transform: scaleY(0);
      opacity: 0;
    }
    100% {
      transform: scaleY(1);
      opacity: 1;
    }
  }

  @keyframes shrink {
    0% {
      transform: scaleY(1);
      opacity: 1;
    }
    100% {
      transform: scaleY(0);
      opacity: 0;
    }
  }
}
